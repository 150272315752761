/* eslint-disable react/style-prop-object */
import './styles.css';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

const Congratulation = () => {
    const { width, height } = useWindowSize()
    return (
        <>
            <Confetti
                width={width}
                height={height}
            />

            <div className="contain">
                <div className="congrats">
                    <h1>Congratulations!</h1>
                    <div className="text">
                        <p>Thanks for taking the time to check out my clothing line! I appreciate your support.</p>
                        <p>Click "NEXT" to explore the amazing collection. Enjoy!</p>
                    </div>
                    <div className="regards">
                        <button
                            class="btn btn-primary"
                            type="submit"
                            onClick={() => window.location.assign('/account/instagram')}
                        > Next
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Congratulation;