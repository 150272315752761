// import './styles.css';

const Promotional = () => {
    return (
        <div class="u-clearfix u-section-2" id="sec-f0b2">
            <div class="u-clearfix u-layout-wrap u-layout-wrap-1">
                <div class="u-layout">
                    <div class="u-layout-row">
                        <div class="u-align-right u-container-style u-layout-cell u-left-cell u-size-30 u-layout-cell-1">
                            <div class="u-container-layout u-valign-middle u-container-layout-1">
                                <h3 class="u-text u-text-default u-text-1">last chance</h3>
                                <h1 class="u-text u-text-default u-text-2">70%
                                    <br />
                                </h1>
                                <h2 class="u-text u-text-default u-text-3">off all</h2>
                                <p class="u-text u-text-default u-text-4">Sample text. Click to select the text box. Click again or double click to start editing the text.</p>
                                <a href="/account/instagram" class="u-active-none u-border-10 u-border-black u-btn u-btn-rectangle u-button-style u-hover-none u-none u-btn-1">start now</a>
                            </div>
                        </div>
                        <div class="u-container-style u-image u-layout-cell u-right-cell u-size-30 u-image-1">
                            <div class="u-container-layout u-container-layout-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Promotional;