import './NavBrand.css'
import { Link } from 'react-router-dom';

const NavBrand = () => {
    return ( 
        <div href="#home" className='navbrand__container'>
            <h1 className='navbrand' title='Infinity Clothing World'> 
                {/* style={{ paddingLeft: "150px", minWidth: "500px", fontSize: "30px", paddingTop: "35px" }} */}
               <Link to="/">ICW</Link>
            </h1>
        </div>
     );
}
 
export default NavBrand;